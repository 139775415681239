// Variables
$primary-color: #1a237e;      // 주요 액션
$secondary-color: #283593;    // 보조 액션
$success-color: #2e7d32;      // 성공, 활성화
$warning-color: #ed6c02;      // 경고, 주의
$error-color: #d32f2f;        // 에러, 감소
$info-color: #0288d1;         // 정보성
$neutral-color: #637381;      // 중립적 액션
$increase-color: #00a76f;     // 증가
$background-color: #f5f6fa;   // 배경
$border-color: #e0e0e0;       // 테두리
$text-color: #2c3e50;         // 기본 텍스트
$white: #ffffff;

// Mixins
@mixin button-base {
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@mixin section-container {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  min-width: 1140px;
}

// Container
.container {
  padding: 24px 32px;
  background-color: $background-color;
  min-height: calc(100vh - 60px);
  width: 100%;
}

// Page Header
.pageHeader {
  margin-bottom: 24px;

  h1 {
    font-size: 24px;
    color: $text-color;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .pageDescription {
    font-size: 14px;
    color: rgba($text-color, 0.7);
  }
}

.clearBoth {
  clear: both;
}

.emtpySpace {
  width: 10px;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th, td {
    padding: 12px 16px;
    text-align: left;
    font-size: 13px;
    border-bottom: 1px solid $border-color;
  }

  th {
    background-color: $background-color;
    color: $text-color;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: darken($background-color, 2%);
    }
  }

  td {
    color: $text-color;
    background-color: $white;
    vertical-align: middle;
  }
}

.pagination {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.separator {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
  background-color: rgba(128, 128, 128, 0.2);
}

.expandedRowContent {
  background-color: #f9f9f9;
}

.expandedCellPlaceholder {
  background-color: transparent;
  border: none;
}

.expandedCellHeader {
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.expandedCell {
  padding: 0;
  background-color: #f9f9f9;
}

.innerTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th {
    background-color: $background-color;
    color: $text-color;
    font-size: 13px;
    padding: 12px 16px;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid $border-color;
  }

  td {
    font-size: 13px;
    padding: 12px 16px;
    background-color: $white;
    border-bottom: 1px solid $border-color;
    color: $text-color;
    vertical-align: middle;
  }
}

.expandedRowItem {
  background-color: #f0f8ff;
}

.budgetBtn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.budgetBtn:hover {
  background-color: #0056b3;
}

.bulkControls {
  display: flex;
  gap: 48px;
  padding: 16px 24px;
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-bottom: 16px;
}

.controlGroup {
  display: flex;
  align-items: center;
  gap: 12px;

  label {
    color: $text-color;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.buttonGroup {
  display: flex;
  gap: 8px;
}

.controlButton {
  padding: 6px 12px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  background-color: $white;
  color: $primary-color;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba($primary-color, 0.04);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: $border-color;
    color: $neutral-color;
  }
}

.budgetControls {
  display: flex;
  gap: 8px;
  align-items: center;
}

.budgetInput {
  width: 120px;
  padding: 6px 12px;
  border: 1px solid $border-color;
  border-radius: 4px;
  font-size: 13px;

  &:focus {
    outline: none;
    border-color: $primary-color;
  }

  &::placeholder {
    color: $neutral-color;
  }
}

.statusButton {
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;
  border: 1px solid $border-color;
  color: $text-color;

  &:hover {
    background-color: $background-color;
  }

  &.active {
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }
}

.bulkActiveButton {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  border-radius: 4px;
}

.applyButton {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.applyButton:hover {
  background-color: #218838;
}

input[type="number"] {
  width: 100px;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inlineGroup {
  display: flex;
  gap: 15px;
}

.syncInfo {
  display: flex;
  flex-direction: row; /* 세로 방향으로 배치 */
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0;
}

.totalAdsLabel {
  font-weight: bold; /* 굵게 강조 */
  font-size: 20px; /* 글자 크기 키움 */
  margin-top: 20px;
}

.syncStatus {
  display: flex;
  align-items: center;
  gap: 10px; /* 동기화 상태 텍스트와 동그라미 사이 간격 */
  font-size: 14px;
  color: #333;
  margin-top: 5px;
  padding-top: 8px;
}

.syncDotContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.syncText {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.greenLight {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #28a745;
}

.redLight {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
}

.syncTime {
  font-size: 12px;
  color: #777;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.noData {
  text-align: center;
  padding: 48px;
  color: $text-color;
  font-size: 14px;
  background-color: $white;
  border-radius: 0 0 8px 8px;
  margin: 0;  // 마진 제거
}

.syncContainer {
  display: flex;
  align-items: center;
  gap: 40px; /* 각 요소 간의 간격 조정 */
  margin-top: 20px; /* "총 데이터 수"와 수동 동기화 버튼의 위치 조정 */
}

.totalAdsWrapper {
}

.totalAdsLabel {
  font-weight: bold; /* 굵게 강조 */
  font-size: 20px; /* 글자 크기 키움 */
}

.syncButtonWrapper {
  flex: none;
}

.syncButton {
  @include button-base;
  background-color: $info-color;
  color: $white;
  padding: 8px 16px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;

  &.syncing {
    pointer-events: none;
    opacity: 0.7;

    .syncIcon {
      animation: spin 1s linear infinite;
    }
  }

  &:hover {
    background-color: darken($info-color, 5%);
  }

  .syncIcon {
    font-size: 16px;
  }
}

.syncButtonRect {
  border-radius: 4px; /* 사각형 모양 */
  padding: 6px 12px; /* 수동 동기화 버튼의 크기 */
  font-size: 15px;
}

.syncButton:disabled {
  background-color: #6c757d; /* 비활성화 상태일 때의 색상 */
  cursor: not-allowed;
}

.syncButton:hover:ENABLE {
  background-color: #218838;
}

.syncIcon {
  font-size: 18px; /* 아이콘 크기 조정 */
}

.syncStatusWrapper {
  flex: none;
}

.syncText {
  font-size: 14px;
  color: #333;
}

.spinning .syncIcon {
  animation: spin 1s linear infinite; /* 아이콘 회전 애니메이션 */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hoverable {
  cursor: pointer;
  
  &:hover {
    background-color: rgba($primary-color, 0.02);
  }
}

.popupBtn {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $primary-color;
  color: $white;
  border: none;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $secondary-color;
  }
}

.commentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.editCommentButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba($primary-color, 0.1);
  }
}

// 예산 조정 관련 스타일
.budgetControls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.budgetButtons {
  display: flex;
  align-items: center;
  gap: 6px;
}

.budgetBtn {
  @include button-base;
  background-color: $white;
  color: $text-color;
  padding: 0 16px;
  height: 32px;
  font-weight: 500;
  min-width: 90px;
  border: 1px solid $border-color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba($primary-color, 0.05);
    border-color: $primary-color;
    color: $primary-color;
  }
}

.percentButton {
  @include button-base;
  padding: 0 12px;
  height: 28px;
  font-size: 12px;
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid $border-color;
  background-color: $white;

  &.increase {
    color: $increase-color;
    
    .icon {
      color: $increase-color;
    }

    &:hover {
      background-color: rgba($increase-color, 0.05);
    }
  }

  &.decrease {
    color: $error-color;
    
    .icon {
      color: $error-color;
    }

    &:hover {
      background-color: rgba($error-color, 0.05);
    }
  }

  .icon {
    font-size: 14px;
  }
}

// 테이블 내 스위치 셀 스타일
.switchCell {
  width: 120px;
  text-align: center;

  .react-switch {
    vertical-align: middle;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// 자세히 보기 버튼 스타일
.insightButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $primary-color;
  color: $white;
  border: none;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $secondary-color;
  }
}

// 메모 확인 버튼 스타일
.commentButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba($primary-color, 0.1);
  }
}

// 데이터 정보 섹션 폰트 크기
.dataInfo {
  .totalCount {
    font-size: 14px;
    font-weight: 500;
  }

  .syncTime {
    font-size: 13px;
  }
}

// 숫자 데이터 정렬
td[data-type="number"] {
  text-align: right;
}

.dataSection {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  width: 100%;
  margin-top: 20px;
}

.dataInfo {
  padding: 16px 24px;
  border-bottom: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


