.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin: 0 0 1rem;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin: 0;
    color: #666;
    line-height: 1.5;

    strong {
      color: #333;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancelButton {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.deleteButton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #d32f2f;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #b71c1c;
  }
} 