.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  flex: 1;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
}

.bodyWrapper {
  display: flex;
  width: 100%;
  flex: 1;
  gap: 1rem;
  padding-right: 1rem;
  overflow: auto;
}