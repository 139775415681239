// Variables
$primary-color: #1a237e;
$secondary-color: #283593;
$background-color: #f5f6fa;
$border-color: #e0e0e0;
$text-color: #2c3e50;
$white: #ffffff;
$error-color: #d32f2f;
$success-color: #2e7d32;

.container {
  padding: 24px 32px;
  background-color: $background-color;
  min-height: calc(100vh - 60px);
  width: 100%;
}

.dataSection {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  width: 100%;
  margin-top: 20px;
}

.dataInfo {
  padding: 16px 24px;
  border-bottom: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalCount {
  font-size: 16px;
  font-weight: 500;
  color: $text-color;
}

.syncContainer {
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 20px 0;
}

.syncInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.syncStatus {
  display: flex;
  align-items: center;
  gap: 8px;

  .syncText {
    font-size: 14px;
    color: $text-color;
  }

  .greenLight {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $success-color;
  }

  .redLight {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $error-color;
  }
}

.syncTime {
  color: $text-color;
  opacity: 0.7;
  font-size: 13px;
}

.syncButton {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: $primary-color;
  color: $white;
  border: none;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background-color: $secondary-color;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.spinning {
    .syncIcon {
      animation: spin 1s linear infinite;
    }
  }
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th, td {
    padding: 12px 16px;
    text-align: left;
    font-size: 13px;
    border-bottom: 1px solid $border-color;
  }

  th {
    background-color: $background-color;
    color: $text-color;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: darken($background-color, 2%);
    }
  }

  td {
    color: $text-color;
  }

  tbody tr {
    &:hover {
      background-color: rgba($primary-color, 0.02);
    }
  }
}

.bulkControls {
  padding: 16px 24px;
  border-bottom: 1px solid $border-color;
  background-color: $background-color;
}

.bulkControlsTitle {
  font-size: 14px;
  font-weight: 500;
  color: $text-color;
  margin-bottom: 12px;
}

.bulkActions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.statusButton {
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;
  border: 1px solid $border-color;
  color: $text-color;

  &:hover {
    background-color: $background-color;
  }

  &.active {
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }
}

.applyButton {
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $success-color;
  color: $white;
  border: none;

  &:hover {
    background-color: darken($success-color, 5%);
  }
}

.insightButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  background-color: $primary-color;
  color: $white;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $secondary-color;
  }
}

.commentButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba($primary-color, 0.04);
  }
}

.loader {
  border: 3px solid $border-color;
  border-top: 3px solid $primary-color;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.noData {
  padding: 48px;
  text-align: center;
  color: $text-color;
  opacity: 0.7;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.clearBoth {
  clear: both;
}

.emtpySpace {
  width: 10px;
}

.pagination {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.separator {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
  background-color: rgba(128, 128, 128, 0.2);
}

.expandedRowContent {
  background-color: #f9f9f9;
}

.expandedCellPlaceholder {
  background-color: transparent;
  border: none;
}

.expandedCellHeader {
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.expandedCell {
  padding: 0;
  background-color: #f9f9f9;
}

.innerTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0;
}

.innerTable th, .innerTable td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.innerTable th {
  background-color: #e9e9e9;
}

.expandedRowItem {
  background-color: #f0f8ff;
}

.popupBtn {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $primary-color;
  color: $white;
  border: none;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $secondary-color;
  }
}

.commentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.editCommentButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba($primary-color, 0.1);
  }
}

.bulkActiveButton {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  border-radius: 4px;
}

.inlineGroup {
  display: flex;
  gap: 15px;
}

.totalAdsWrapper {
}

.totalAdsLabel {
  font-weight: bold; /* 굵게 강조 */
  font-size: 20px; /* 글자 크기 키움 */
}

.syncButtonWrapper {
  flex: none;
}

.syncIcon {
  font-size: 18px; /* 아이콘 크기 조정 */
}

.syncStatusWrapper {
  flex: none;
}

.pageTitle {
  font-size: 24px;
  font-weight: 600;
  color: $text-color;
  margin-bottom: 24px;
}

.switchWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.switch {
  :global {
    .react-switch-bg {
      background-color: #e4e4e4 !important;
      
      &[style*="background-color: rgb(26, 35, 126)"] {
        background-color: #1a237e !important;
      }
    }
    
    .react-switch-handle {
      background-color: #ffffff !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
      top: 2px !important;  // 핸들 위치 조정
    }
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 48px;

  .loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #1a237e;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }

  span {
    color: $text-color;
    font-size: 14px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

