// Variables
$primary-color: #1a237e;
$secondary-color: #283593;
$background-color: #f5f6fa;
$border-color: #e0e0e0;
$text-color: #2c3e50;
$white: #ffffff;
$error-color: #d32f2f;
$success-color: #2e7d32;

// Mixins
@mixin input-base {
  height: 32px;
  padding: 0 12px;
  border: 1px solid $border-color;
  border-radius: 4px;
  font-size: 13px;
  color: $text-color;
  background-color: $white;
  transition: all 0.2s;

  &:focus {
    border-color: $primary-color;
    outline: none;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
  }
}

.searchContainer {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 16px;
  margin-bottom: 20px;

  h2 {
    font-size: 16px;
    color: $text-color;
    margin-bottom: 12px;
    font-weight: 500;
  }
}

.searchGroup {
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.flexContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.searchItem {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 13px;
    color: $text-color;
    font-weight: 500;
    white-space: nowrap;
    margin-right: 4px;
  }

  select, input {
    height: 32px;
    padding: 0 8px;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 13px;
    color: $text-color;
    
    &:focus {
      border-color: $primary-color;
      outline: none;
      box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
    }
  }
}

.largeSelect {
  width: 360px;
  
  select {
    width: 100%;
  }
}

.smallSelect {
  width: 160px;
  
  select {
    width: 100%;
  }
}

.searchInput {
  width: 200px;
  
  input {
    width: 100%;
  }
}

// 조건 버튼 (ON/OFF, ≥/≤)
.conditionButton {
  min-width: 32px;
  height: 32px;
  padding: 0 6px;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: $white;
  color: $text-color;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  margin: 0 2px;

  &:hover {
    background-color: rgba($primary-color, 0.05);
  }

  &.active {
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }
}

.inlineGroup {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 4px;

  .searchInput {
    width: 130px;
  }
}

// DatePicker 스타일
.searchFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid $border-color;
}

.datePicker {
  display: flex;
  align-items: center;
  gap: 8px;
}

.datePickerItem {
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    font-size: 13px;
    color: $text-color;
    font-weight: 500;
    margin: 0;
  }
}

.customDatePicker {
  @include input-base;
  width: 120px;
}

.datePickerSeparator {
  color: $text-color;
  font-size: 13px;
  margin: 0 4px;
}

// 검색 버튼
.searchBtn {
  height: 32px;
  padding: 0 20px;
  background-color: $primary-color;
  color: $white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 16px;

  &:hover {
    background-color: $secondary-color;
  }
}

// 에러 상태
.invalidField {
  input, select {
    border-color: $error-color;
  }
}

.errorText {
  color: $error-color;
  font-size: 12px;
  margin-top: 4px;
}

// 필터 그룹
.filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.datePickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: $white;
  border-bottom: 1px solid $border-color;

  button {
    padding: 4px 8px;
    background: none;
    border: 1px solid $border-color;
    border-radius: 4px;
    cursor: pointer;
    color: $text-color;

    &:hover:not(:disabled) {
      background-color: rgba($primary-color, 0.05);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: $text-color;
  }
}

// react-datepicker 커스텀 스타일
:global {
  .react-datepicker {
    font-family: inherit;
    border-color: $border-color;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    &__header {
      background-color: $white;
      border-bottom: 1px solid $border-color;
    }

    &__day {
      &:hover {
        background-color: rgba($primary-color, 0.05);
      }

      &--selected {
        background-color: $primary-color;
        color: $white;

        &:hover {
          background-color: darken($primary-color, 5%);
        }
      }

      &--disabled {
        color: lighten($text-color, 40%);
      }
    }
  }
}

// 알림 메시지 스타일
.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
  max-width: 400px;
  border-left: 4px solid;

  &.warning {
    border-left-color: #f59e0b;
    .icon {
      color: #f59e0b;
    }
  }

  &.info {
    border-left-color: #3b82f6;
    .icon {
      color: #3b82f6;
    }
  }

  .icon {
    font-size: 20px;
  }

  .message {
    color: $text-color;
    font-size: 14px;
    line-height: 1.5;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


