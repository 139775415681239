.sidebar {
  width: 260px;
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem 1rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.03);
  
  &.collapsed {
    width: 72px;
    padding: 2rem 0.6rem;
    
    .nav {
      align-items: center;
    }
    
    .navItem, .active {
      font-size: 12px;
      justify-content: center;
    }

    .toggleButton {
      right: -10px;
    }
  }
}

.toggleButton {
  position: absolute;
  right: -12px;
  top: 24px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  &:hover {
    background-color: #fafafa;
    border-color: rgba(0, 0, 0, 0.12);
    transform: scale(1.05);
  }
  
  svg {
    width: 14px;
    height: 14px;
    color: #555;
    transition: color 0.2s ease;
  }

  &:hover svg {
    color: #333;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 0.5rem;
}

.navItem {
  color: #666;
  text-decoration: none;
  padding: 0.6rem 0.8rem;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.3px;
  transition: all 0.2s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #333;
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.active {
  color: #000;
  font-weight: 600;
  font-size: 13px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.03);
  
  &::before {
    content: '';
    position: absolute;
    left: -1rem;
    top: 50%;
    width: 2px;
    height: 16px;
    background-color: #000;
    transform: translateY(-50%);
    border-radius: 4px;
  }
}

.subMenu {
  margin: 0.4rem 0 0.4rem 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-5px);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  
  &.showSubMenu {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

.subNavItem {
  color: #777;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  
  &:hover {
    color: #333;
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.subActive {
  color: #000;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
}