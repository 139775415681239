.footer {
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
  padding: 0.8rem 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  text-align: center;
  font-size: 12px;
  margin-top: auto; // 컨텐츠가 짧을 때 하단에 붙도록 함
}

.footerLinks {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 0.5rem;
}

.footerButton {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 12px;    // 폰트 사이즈 축소
  padding: 0.3rem 0.8rem; // 패딩 축소
  transition: color 0.2s ease;
  
  &:hover {
    color: #333;
  }
}

.companyInfo {
  color: #666;
  font-size: 11px;    // 폰트 사이즈 축소
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  
  p {
    margin: 0;
    line-height: 1.5; // 줄간격 축소
  }
}