.searchContainer {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 16px;
  margin-bottom: 20px;

  h2 {
    font-size: 16px;
    color: #2c3e50;
    margin-bottom: 12px;
    font-weight: 500;
  }
}

.searchGroup {
  margin-bottom: 12px;
}

.flexContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.searchItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 360px;

  label {
    font-size: 13px;
    color: #2c3e50;
    font-weight: 500;
  }

  select {
    height: 32px;
    padding: 0 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 13px;
    color: #2c3e50;
    width: 100%;
    
    &:focus {
      border-color: #1a237e;
      outline: none;
      box-shadow: 0 0 0 2px rgba(26, 35, 126, 0.1);
    }
  }
}

.searchFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #e0e0e0;
}

.datePicker {
  display: flex;
  align-items: center;
  gap: 8px;
}

.datePickerItem {
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    font-size: 13px;
    color: #2c3e50;
    font-weight: 500;
  }
}

.customDatePicker {
  height: 32px;
  padding: 0 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 13px;
  color: #2c3e50;
  width: 120px;

  &:focus {
    border-color: #1a237e;
    outline: none;
    box-shadow: 0 0 0 2px rgba(26, 35, 126, 0.1);
  }
}

.datePickerSeparator {
  color: #2c3e50;
  font-size: 13px;
}

.searchBtn {
  height: 32px;
  padding: 0 20px;
  background-color: #1a237e;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 16px;

  &:hover {
    background-color: #283593;
  }
}

.invalidField {
  select {
    border-color: #d32f2f;
  }
}

.errorText {
  color: #d32f2f;
  font-size: 12px;
  margin-top: 4px;
}

.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
  max-width: 400px;
  border-left: 4px solid;

  &.warning {
    border-left-color: #f59e0b;
  }

  &.info {
    border-left-color: #3b82f6;
  }

  .message {
    color: #2c3e50;
    font-size: 14px;
    line-height: 1.5;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
} 