.container {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: #fff;
  overflow-y: auto;
}

.section {
  margin-bottom: 2.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.2s;

  &:hover {
    color: #333;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.card {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid #eaeaea;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  width: 100%;
  box-sizing: border-box;
}

.field {
  margin-bottom: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  
  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: block;
    font-size: 14px;
    color: #666;
    margin-bottom: 0.6rem;
    font-weight: 500;
    letter-spacing: -0.2px;
  }

  input {
    width: 100%;
    padding: 0.9rem 1.2rem;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    background-color: #fafafa;
    box-sizing: border-box;
    
    &:read-only {
      cursor: default;
      color: #666;
    }
  }
}

.platformSection {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: 1px solid #eaeaea;
  width: 100%;  // 전체 너비 사용

  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

.platformHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.8rem 0;
  color: #333;
  transition: all 0.2s ease;
  
  &:hover {
    color: #000;
  }

  svg {
    width: 24px;
    height: 24px;
    color: #666;
  }
}

.platformTitle {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  gap: 0.8rem;
  letter-spacing: -0.3px;
}

.platformIcon {
  width: 22px;
  height: 22px;
  color: #666;
}

.accountsList {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;  // 전체 너비 사용
}

.accountCard {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 2.5rem;
  border: 1px solid #eaeaea;
  width: 100%;
  box-sizing: border-box;

  .field {
    margin-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      font-size: 16px;
      margin-bottom: 0.8rem;
    }

    input {
      padding: 1.2rem 1.4rem;
      font-size: 16px;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
  color: #666;
  letter-spacing: -0.2px;
}

.usersList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // 한 줄에 여러 카드
  gap: 1.5rem;
}

.userCard {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 1.8rem; // 패딩 약간 줄임
  border: 1px solid #eaeaea;
  width: 100%;

  .field {
    margin-bottom: 1.5rem; // 간격 약간 줄임

    &:last-child {
      margin-bottom: 0;
    }

    label {
      font-size: 14px; // 폰트 크기 약간 줄임
      margin-bottom: 0.6rem;
    }

    input {
      padding: 0.9rem 1.2rem; // 패딩 약간 줄임
      font-size: 14px; // 폰트 크기 약간 줄임
    }
  }
}

.userTableCard {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
}

.userTable {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }

  th {
    color: #4b5563;
    font-weight: 600;
    font-size: 14px;
    background-color: #f9fafb;
  }

  td {
    color: #1f2937;
    font-size: 14px;
  }

  tr:last-child td {
    border-bottom: none;
  }

  tr:hover td {
    background-color: #f9fafb;
  }
}

.actionCell {
  text-align: right;
  padding-right: 24px !important;
  white-space: nowrap;
  min-width: 180px;
}

.actionButtons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.editButton, .deleteButton {
  padding: 8px 20px;
  background-color: white;
  border: 1.5px solid;  // 테두리 두께 증가
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  min-width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.editButton {
  color: #0F172A;
  border-color: #0F172A;
  
  &:hover {
    background-color: #F8FAFC;
    color: #1E293B;
    border-color: #1E293B;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: #F1F5F9;
    transform: translateY(1px);
  }

  &:disabled {
    color: #94A3B8;
    border-color: #94A3B8;
    background-color: #F8FAFC;
    cursor: not-allowed;
    box-shadow: none;
  }
}

.deleteButton {
  color: #991B1B;
  border-color: #991B1B;
  
  &:hover {
    background-color: #FEF2F2;
    color: #7F1D1D;
    border-color: #7F1D1D;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: #FEE2E2;
    transform: translateY(1px);
  }

  &:disabled {
    color: #FCA5A5;
    border-color: #FCA5A5;
    background-color: #FEF2F2;
    cursor: not-allowed;
    box-shadow: none;
  }
}

.searchWrapper {
  padding: 0 0 1rem 0;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
}

.searchInput {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  background-color: #fafafa;
  transition: all 0.2s;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #666;
    background-color: #fff;
  }

  &::placeholder {
    color: #999;
  }
}

.headerActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.addButton {
  padding: 8px 20px;
  background-color: white;
  border: 1.5px solid #2563eb;  // 파란색 테두리
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  min-width: 100px;  // 텍스트가 더 길어서 최소 너비 증가
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #2563eb;  // 파란색 텍스트
  
  &:hover {
    background-color: #F0F7FF;
    color: #1D4ED8;
    border-color: #1D4ED8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: #E0F2FE;
    transform: translateY(1px);
  }

  &:disabled {
    color: #93C5FD;
    border-color: #93C5FD;
    background-color: #F0F7FF;
    cursor: not-allowed;
    box-shadow: none;
  }
}

.statusMessage {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
  
  &.success {
    background-color: #f0fdf4;
    color: #16a34a;
    border: 1px solid #bbf7d0;
  }
  
  &.error {
    background-color: #fef2f2;
    color: #dc2626;
    border: 1px solid #fecaca;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}