// Variables
$primary-color: #1a237e;
$secondary-color: #283593;
$background-color: #f5f6fa;
$border-color: #e0e0e0;
$text-color: #2c3e50;
$white: #ffffff;
$neutral-color: #637381;

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  padding: 20px;

  & + footer {
    display: none;
  }
}

.popupContent {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 20px;
  width: 95%;
  max-width: 1200px;
  max-height: 80vh;
  overflow-y: auto;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: $border-color;
    border-radius: 3px;
    
    &:hover {
      background: darken($border-color, 10%);
    }
  }
}

.popupHeader {
  position: sticky;
  top: 0;
  background-color: $white;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid $border-color;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 20px;
    color: $text-color;
    font-weight: 500;
  }

  .closeButton {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid $border-color;
    background-color: $white;
    color: $text-color;
    font-size: 16px;
    cursor: pointer;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    &:hover {
      background-color: $background-color;
    }
  }
}

.adDetailSection {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: calc(80vh - 100px);
}

.materialSection {
  flex: 0 0 auto;
  width: 400px;
  min-width: 300px;
  max-width: 400px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $border-color;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  h4 {
    color: $text-color;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  .adBody {
    font-size: 14px;
    line-height: 1.6;
    color: $text-color;
    word-break: break-word;
  }

  .adLink {
    color: $primary-color;
    text-decoration: none;
    font-size: 14px;
    word-break: break-all;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .imageWrapper {
    position: relative;
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    background-color: $background-color;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .adImage {
      width: 100%;
      height: auto;
      max-height: 300px;
      object-fit: contain;
      border-radius: 4px;
    }

    .noImage {
      color: $text-color;
      font-size: 14px;
      opacity: 0.7;
    }
  }
}

.insightSection {
  flex: 1;
  min-width: 400px;
  margin-left: 20px;
  
  h3 {
    color: $text-color;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }
}

.tableSection {
  margin-top: 16px;
  max-height: calc(80vh - 400px);
  overflow-y: auto;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $border-color;
  padding: 20px;

  h3 {
    color: $text-color;
    margin-bottom: 16px;
  }
}

.dataTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th {
    background-color: $primary-color;
    color: $white;
    padding: 12px 16px;
    font-weight: 500;
    text-align: left;
    font-size: 13px;

    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid $border-color;
    color: $text-color;
    font-size: 13px;
  }

  tbody tr:hover {
    background-color: rgba($primary-color, 0.02);
  }
}

.historySection {
  margin-top: 24px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $border-color;
  padding: 20px;

  h3 {
    color: $text-color;
    margin-bottom: 16px;
  }
}

.contentWrapper {
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
}


