// Variables
$primary-color: #1a237e;
$secondary-color: #283593;
$background-color: #f5f6fa;
$border-color: #e0e0e0;
$text-color: #2c3e50;
$white: #ffffff;
$neutral-color: #637381;

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  padding: 20px;
}

.popupContent {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 24px;
  width: 95%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 0 auto;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: $border-color;
    border-radius: 3px;
    
    &:hover {
      background: darken($border-color, 10%);
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.section {
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  position: relative;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $border-color;
    border-radius: 4px;
    
    &:hover {
      background: darken($border-color, 10%);
    }
  }

  h3 {
    font-size: 17px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 20px;
  }

  .dataTable {
    width: 100%;
    min-width: auto;
    border-collapse: collapse;
    table-layout: fixed;
    
    th, td {
      padding: 12px 16px;
      text-align: left;
      border: 1px solid $border-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th {
      background-color: rgba($primary-color, 0.05);
      font-weight: 500;
    }
  }
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;

  h3 {
    font-size: 16px;
    color: $text-color;
    font-weight: 500;
  }
}

.toggleButton {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid $border-color;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $background-color;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid $border-color;

  h2 {
    font-size: 20px;
    color: $text-color;
    font-weight: 500;
  }

  .closeButton {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid $border-color;
    background-color: $white;
    color: $text-color;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    &:hover {
      background-color: $background-color;
    }
  }
}

.leftSection {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    color: $text-color;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }
}

.rightSection {
  flex: 1;
  min-width: 300px;

  h3 {
    color: $text-color;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }
}

.adDetailWrapper {
  padding: 16px;
  background-color: rgba($background-color, 0.3);
  overflow-x: auto;
  
  .insightTable {
    width: 100%;
    min-width: auto;
    border-collapse: collapse;
    table-layout: fixed;
    
    th, td {
      padding: 12px 16px;
      text-align: left;
      border: 1px solid $border-color;
      white-space: nowrap;
    }

    th {
      background-color: rgba($primary-color, 0.05);
      font-weight: 500;
    }

    th, td {
      &:nth-child(1) { width: 130px; }
      &:nth-child(2) { width: 100px; }
      &:nth-child(3) { width: 100px; }
      &:nth-child(4) { width: 100px; }
      &:nth-child(5) { width: 90px; }
      &:nth-child(6) { width: 90px; }
      &:nth-child(7) { width: 100px; }
    }
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $border-color;
    border-radius: 4px;
    
    &:hover {
      background: darken($border-color, 10%);
    }
  }
}

.historySection {
  .dataTable {
    min-width: auto;
    
    th, td {
      &:nth-child(1) { width: 15%; }
      &:nth-child(2) { width: 15%; }
      &:nth-child(3) { width: 15%; }
      &:nth-child(4) { width: 55%; }
    }
  }
}

.adListSection {
  .dataTable {
    min-width: auto;
    
    th, td {
      &:nth-child(1) { width: 10%; }
      &:nth-child(2) { width: 20%; }
      &:nth-child(3) { width: 55%; }
      &:nth-child(4) { width: 15%; }
    }
  }
}

.actionButtons {
  margin-bottom: 16px;
}

.viewDetailButton {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid $border-color;
  background-color: $white;
  color: $text-color;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s;

  &:hover {
    background-color: $background-color;
    border-color: $primary-color;
    color: $primary-color;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.insightTable {
  width: 100% !important;
  table-layout: fixed;
  
  th, td {
    padding: 12px 16px;
    text-align: left;
    border: 1px solid $border-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    background-color: rgba($primary-color, 0.05);
    font-weight: 500;
    width: 30%;
  }

  td {
    width: 70%;
  }

  tbody tr {
    &:hover {
      background-color: rgba($primary-color, 0.02);
    }
  }
}

.adDetailSection {
  display: flex;
  gap: 24px;
}

.materialSection {
  width: 45%;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $border-color;
  padding: 20px;

  h4 {
    color: $text-color;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }

  .adBody {
    font-size: 14px;
    line-height: 1.6;
    color: $text-color;
    margin-bottom: 16px;
  }

  .adLink {
    color: $primary-color;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 16px;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .adImage {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 4px;
    margin-top: 16px;
  }
}

.insightSection {
  width: 55%;
  
  .insightTable {
    width: 100%;
    border-collapse: collapse;
    
    th {
      width: 30%;
      background-color: rgba($primary-color, 0.05);
    }

    td {
      width: 70%;
    }

    th, td {
      padding: 12px 16px;
      text-align: left;
      border: 1px solid $border-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.adsListWrapper {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid $border-color;
  border-radius: 8px;
}

.thumbnailCell {
  width: 80px;
  padding: 8px !important;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  display: block;
}

.noThumbnail {
  width: 60px;
  height: 60px;
  background-color: $background-color;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: $neutral-color;
}

.clickableRow {
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba($primary-color, 0.02);
  }

  &.selectedRow {
    background-color: rgba($primary-color, 0.05);
    border-left: 3px solid $primary-color;

    td {
      color: $primary-color;
      font-weight: 500;

      &:first-child {
        padding-left: 13px;
      }
    }
  }
}

.insightRow {
  background-color: $background-color;
  
  td {
    padding: 16px;
  }
}

.selectedRow {
  background-color: rgba($primary-color, 0.05);
  border-left: 3px solid $primary-color;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.modalContent {
  background-color: $white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;

  .insightTable {
    width: 100%;
    
    th {
      width: 30%;
    }
    
    td {
      width: 70%;
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid $border-color;

  h3 {
    font-size: 18px;
    color: $text-color;
    font-weight: 500;
  }

  .modalCloseButton {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid $border-color;
    background-color: $white;
    color: $text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    &:hover {
      background-color: $background-color;
    }
  }
}

.modalBody {
  padding: 20px;
}

.adDetailSection {
  display: flex;
  gap: 24px;
}

.materialSection {
  width: 45%;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $border-color;
  padding: 20px;

  h4 {
    color: $text-color;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }

  .adBody {
    font-size: 14px;
    line-height: 1.6;
    color: $text-color;
    margin-bottom: 16px;
  }

  .adLink {
    color: $primary-color;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 16px;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .adImage {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 4px;
    margin-top: 16px;
  }
}

.insightSection {
  width: 55%;
  
  .insightTable {
    width: 100%;
    border-collapse: collapse;
    
    th {
      width: 30%;
      background-color: rgba($primary-color, 0.05);
    }

    td {
      width: 70%;
    }

    th, td {
      padding: 12px 16px;
      text-align: left;
      border: 1px solid $border-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
