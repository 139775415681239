.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin: 0 0 1.5rem;
    font-size: 20px;
    font-weight: 600;
  }
}

.field {
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: #666;
  }

  input, select {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;

    &:focus {
      outline: none;
      border-color: #1a73e8;
    }
  }
}

.error {
  background-color: #fef2f2;
  color: #dc2626;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 14px;
  border: 1px solid #fecaca;
}

.success {
  background-color: #f0fdf4;
  color: #16a34a;
  padding: 12px 16px;
  border-radius: 4px;
  margin: 1rem 0;
  font-size: 14px;
  border: 1px solid #bbf7d0;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancelButton, .submitButton {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.cancelButton {
  border: 1px solid #ddd;
  background-color: white;

  &:hover:not(:disabled) {
    background-color: #f5f5f5;
  }
}

.submitButton {
  border: none;
  background-color: #1a73e8;
  color: white;

  &:hover:not(:disabled) {
    background-color: #1557b0;
  }
} 