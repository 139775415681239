.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


textarea {
  width: 96%;
  height: 60px; /* 약 3줄 높이 */
  resize: none; /* 사용자가 크기를 조절하지 못하게 함 */
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}


.popupContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popupActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}
