.container {
  padding: 24px 32px;
  background-color: #f5f6fa;
  min-height: calc(100vh - 60px);
  width: 100%;
}

.loadingWrapper {
  padding: 48px;
  text-align: center;
  color: #2c3e50;
  
  .loader {
    border: 3px solid #e0e0e0;
    border-top: 3px solid #1a237e;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto 16px;
  }
}

.tableWrapper {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 16px;
  overflow-x: auto;
}

.dataTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 1140px;
  
  th {
    background-color: #f8f9fa;
    color: #2c3e50;
    font-size: 13px;
    padding: 12px 16px;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    position: relative;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  td {
    font-size: 13px;
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
    color: #2c3e50;
  }

  tbody tr:hover {
    background-color: rgba(26, 35, 126, 0.02);
  }
}

.dataInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.totalCount {
  color: #637381;
  font-size: 13px;
}

.syncInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.syncStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.syncSuccess {
  color: #2e7d32;
}

.syncError {
  color: #d32f2f;
}

.syncTime {
  color: #637381;
}

.syncButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background-color: #1a237e;
  color: white;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: darken(#1a237e, 5%);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.syncing .syncIcon {
    animation: spin 1s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.sortIcon {
  font-size: 18px;
  color: #1a237e;
  vertical-align: middle;
  margin-left: 4px;
}

.campaignRow {
  cursor: pointer;

  &:hover {
    background-color: rgba(26, 35, 126, 0.02);
  }

  &.expanded {
    background-color: rgba(26, 35, 126, 0.05);
  }
}

.adGroupsContainer {
  padding: 16px;
  background-color: #f8f9fa;
}

.adGroupsTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th {
    background-color: #ffffff;
    color: #2c3e50;
    font-size: 12px;
    padding: 8px 12px;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    font-size: 12px;
    padding: 8px 12px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #ffffff;
  }

  tbody tr:hover {
    background-color: rgba(26, 35, 126, 0.02);
  }
}

.memoButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
}

.hasMemo {
  font-size: 20px;
  color: #1a237e;
}

.noMemo {
  font-size: 20px;
  color: #9e9e9e;
}

.budgetControls {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  .budgetInput {
    width: 100px;
    min-width: 100px;
    padding: 4px 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: #1a237e;
    }
  }

  .budgetButtons {
    display: flex;
    gap: 4px;
    flex-shrink: 0;
  }

  .budgetBtn {
    padding: 4px 8px;
    min-width: 70px;
    background-color: #1a237e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    flex-shrink: 0;

    &:hover {
      background-color: #0d47a1;
    }
  }

  .percentButton {
    padding: 4px 8px;
    min-width: 60px;
    border: 1px solid #e0e0e0;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    flex-shrink: 0;
    white-space: nowrap;

    &.increase {
      color: #2e7d32;
      &:hover {
        background-color: #e8f5e9;
      }
    }

    &.decrease {
      color: #d32f2f;
      &:hover {
        background-color: #ffebee;
      }
    }

    .icon {
      font-size: 10px;
    }
  }
}

.detailButton {
  padding: 4px 8px;
  min-width: 70px;
  background-color: #1a237e;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
  flex-shrink: 0;

  &:hover {
    background-color: #0d47a1;
  }
} 