// Variables
$primary-color: #1a237e;      // 주요 액션
$secondary-color: #283593;    // 보조 액션
$success-color: #2e7d32;      // 성공, 활성화
$warning-color: #ed6c02;      // 경고, 주의
$error-color: #d32f2f;        // 에러, 감소
$info-color: #0288d1;         // 정보성
$neutral-color: #637381;      // 중립적 액션
$increase-color: #00a76f;     // 증가
$background-color: #f5f6fa;   // 배경
$border-color: #e0e0e0;       // 테두리
$text-color: #2c3e50;         // 기본 텍스트
$white: #ffffff;

// Mixins
@mixin button-base {
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@mixin section-container {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  min-width: 1140px;
}

// Container
.container {
  padding: 24px 32px;
  background-color: $background-color;
  min-height: calc(100vh - 60px);
  width: 100%;
}

// Page Header
.pageHeader {
  margin-bottom: 24px;

  h1 {
    font-size: 24px;
    color: $text-color;
    margin-bottom: 8px;
  }

  .pageDescription {
    font-size: 14px;
    color: rgba($text-color, 0.7);
  }
}

// Search Section
.searchSection {
  @include section-container;
}

// Bulk Controls
.uploadSection {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.bulkControls {
  padding: 16px 24px;
}

.bulkGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .bulkItem {
    display: flex;
    align-items: center;
    gap: 16px;

    label {
      font-size: 14px;
      font-weight: 500;
      color: $text-color;
      min-width: 120px;
    }

    .buttonGroup {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.statusButton {
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;
  border: 1px solid $border-color;
  color: $text-color;

  &:hover {
    background-color: $background-color;
  }

  &.active {
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }
}

.applyButton {
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $success-color;
  color: $white;
  border: none;

  &:hover {
    background-color: darken($success-color, 5%);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.buttonGroup {
  display: flex;
  gap: 8px;
}

// 각 버튼 스타일
.syncButton {
  @include button-base;
  background-color: $info-color;
  color: $white;
  padding: 0 16px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.budgetBtn {
  @include button-base;
  background-color: $white;
  color: $text-color;
  padding: 0 16px;
  height: 32px;
  font-weight: 500;
  min-width: 90px;
  border: 1px solid $border-color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba($primary-color, 0.05);
    border-color: $primary-color;
    color: $primary-color;
  }
}

.increaseButton {
  @include button-base;
  background-color: $increase-color;
  color: $white;
  padding: 0 12px;
  height: 28px;
}

.decreaseButton {
  @include button-base;
  background-color: $error-color;
  color: $white;
  padding: 0 12px;
  height: 28px;
}

.insightButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $primary-color;
  color: $white;
  border: none;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $secondary-color;
  }
}

.commentButton {
  min-width: 90px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba($primary-color, 0.1);
  }
}

// Input Styles
.budgetInput {
  height: 32px;
  padding: 0 12px;
  border: 1px solid $border-color;
  border-radius: 4px;
  font-size: 13px;
  width: 120px;
  color: $text-color;

  &:focus {
    border-color: $primary-color;
    outline: none;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// Data Section
.dataSection {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.dataInfo {
  padding: 16px 24px;
  border-bottom: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalCount {
  font-size: 16px;
  font-weight: 500;
  color: $text-color;
}

.syncInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.syncStatus {
  display: flex;
  align-items: center;
  gap: 8px;

  .syncSuccess {
    color: $success-color;
    font-weight: 500;
  }

  .syncError {
    color: $error-color;
    font-weight: 500;
  }
}

.syncTime {
  color: $neutral-color;
  font-size: 13px;
}

// Table Styles
.tableWrapper {
  padding: 24px;
  overflow-x: auto;
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th, td {
    padding: 12px 16px;
    text-align: left;
    font-size: 13px;
    border-bottom: 1px solid $border-color;
  }

  th {
    background-color: $background-color;
    color: $text-color;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: darken($background-color, 2%);
    }
  }

  td {
    color: $text-color;
    background-color: $white;
  }

  tbody tr {
    &:hover {
      td {
        background-color: rgba($primary-color, 0.02);
      }
    }
  }

  .checkboxCell {
    width: 40px;
    text-align: center;
    
    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .switchCell {
    width: 60px;
    text-align: center;
  }
}

// 숫자 데이터 셀 정렬
td[data-type="number"] {
  text-align: right;
}

// Budget Controls
.budgetControls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.budgetButtons {
  display: flex;
  align-items: center;
  gap: 6px;
}

// API 호출하는 실제 적용 버튼
.budgetBtn {
  @include button-base;
  background-color: $white;
  color: $text-color;
  padding: 0 16px;
  height: 32px;
  font-weight: 500;
  min-width: 90px;
  border: 1px solid $border-color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba($primary-color, 0.05);
    border-color: $primary-color;
    color: $primary-color;
  }
}

// 단순 계산 버튼
.percentButton {
  @include button-base;
  padding: 0 12px;
  height: 28px;
  font-size: 12px;
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid $border-color;
  background-color: $white;

  &.increase {
    color: $increase-color;
    
    .icon {
      color: $increase-color;
    }

    &:hover {
      background-color: rgba($increase-color, 0.05);
    }
  }

  &.decrease {
    color: $error-color;
    
    .icon {
      color: $error-color;
    }

    &:hover {
      background-color: rgba($error-color, 0.05);
    }
  }

  .icon {
    font-size: 14px;
  }
}

// Loading State
.loadingWrapper {
  padding: 48px;
  text-align: center;
  color: $text-color;
  
  .loader {
    border: 3px solid $border-color;
    border-top: 3px solid $primary-color;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto 16px;
  }
}

.noData {
  padding: 48px;
  text-align: center;
  color: $text-color;
  opacity: 0.7;
}

// Expanded Row Styles
.expandedContent {
  padding: 16px;
  background-color: $background-color;
}

.innerTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th {
    background-color: $background-color;
    color: $text-color;
    font-size: 13px;
    padding: 12px 16px;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid $border-color;
  }

  td {
    font-size: 13px;
    padding: 12px 16px;
    background-color: $white;
    border-bottom: 1px solid $border-color;
    color: $text-color;
  }

  tbody tr:hover {
    td {
      background-color: rgba($primary-color, 0.02);
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}