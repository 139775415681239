// Variables (MetaSetData와 동일)
$primary-color: #1a237e;
$secondary-color: #283593;
$background-color: #f5f6fa;
$border-color: #e0e0e0;
$text-color: #2c3e50;
$white: #ffffff;
$error-color: #d32f2f;
$success-color: #2e7d32;

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  padding: 20px;

  & + footer {
    display: none;
  }
}

.popupContent {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 24px;
  width: 400px;

  h3 {
    font-size: 18px;
    color: $text-color;
    margin-bottom: 16px;
    font-weight: 500;
  }

  textarea {
    width: 100%;
    height: 120px;
    padding: 12px;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 14px;
    resize: none;
    margin-bottom: 16px;
    
    &:focus {
      border-color: $primary-color;
      outline: none;
      box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
    }
  }
}

.popupActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  button {
    padding: 0 16px;
    height: 32px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    &:first-child {
      background-color: $primary-color;
      color: $white;
      border: none;

      &:hover {
        background-color: $secondary-color;
      }
    }

    &:last-child {
      background-color: $white;
      color: $text-color;
      border: 1px solid $border-color;

      &:hover {
        background-color: $background-color;
      }
    }
  }
}
