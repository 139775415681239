.login_container {
  width: 100%;
  height: 100vh;

  .logo_wrap {
    width: 100%;
    margin-top: 10.7rem;
    margin-bottom: 3.9rem;

    .logo {
      width: 25rem;
      height: 3rem;
      margin: 0 auto 2.5rem;
      text-indent: -9999px;
      background: url('./assets/awake-logo.svg') no-repeat 0 center;
      background-size: contain;
    }

  }

  .sns_login_wrap {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    padding: 2.5rem 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    border-radius: 1rem 1rem 1rem 1rem;
    background: #ffffff; /* neutral-white에 해당하는 색상 */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);

    .sns_btn {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center; /* 텍스트를 가운데 정렬 */

      border-radius: 0.5rem;
      border: 1px solid #cccccc; /* neutral-gray-300에 해당하는 색상 */
      background: #ffffff; /* neutral-white에 해당하는 색상 */

      cursor: pointer;

      img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }

      p {
        font-size: 1.2rem;
        color: #666666; /* neutral-gray-700에 해당하는 색상 */
        margin: 0; /* 텍스트 가운데 정렬을 위해 여백 제거 */
        font-weight: bold;
      }
    }

    .sns_btn.active {
      background-color: #f0f0f0; /* 클릭된 느낌을 주기 위한 연한 배경색 */
    }
  }
}

.error_content {
  text-align: center; /* 텍스트를 가운데 정렬 */
}

.error_title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #d32f2f;
  margin-bottom: 0.5rem;
}

.error_message {
  font-size: 1rem;
  margin-top: 15px;
  color: #f44336;
}

.login_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  .input_field {
    height: 40px;
    padding: 0 1rem;
    border: 1px solid #cccccc;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: #666666;
  }

  .login_btn {
    width: 100%;
    height: 40px;
    background-color: #666666;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 0.5rem;
    cursor: pointer;
    border: none;
    text-align: center;

    &:hover {
      background-color: #555555;
    }
  }
}


