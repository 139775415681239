$primary-color: #1a237e;
$secondary-color: #283593;
$background-color: #f5f6fa;
$border-color: #e0e0e0;
$text-color: #2c3e50;
$white: #ffffff;

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  padding: 20px;
}

.popupContent {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 24px;
  width: 1200px;
  max-height: 90vh;
  overflow-y: auto;

  h3 {
    font-size: 18px;
    color: $text-color;
    margin-bottom: 24px;
    font-weight: 500;
  }

  h4 {
    font-size: 16px;
    color: $text-color;
    margin-bottom: 16px;
    font-weight: 500;
  }
}

.section {
  margin-bottom: 32px;
  border: 1px solid $border-color;
  border-radius: 8px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f8f9fa;
  cursor: pointer;
  border-radius: 8px 8px 0 0;

  &:hover {
    background-color: #f1f3f5;
  }

  h4 {
    margin: 0;
    font-size: 16px;
    color: $text-color;
    font-weight: 500;
  }

  svg {
    font-size: 24px;
    color: #637381;
  }
}

.infoGrid {
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    font-size: 12px;
    color: #637381;
  }

  .value {
    font-size: 14px;
    color: $text-color;
  }
}

.tableWrapper {
  padding: 16px;
  overflow-x: auto;
}

.insightTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th {
    background-color: #f8f9fa;
    color: $text-color;
    font-size: 13px;
    padding: 12px 16px;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid $border-color;
    white-space: nowrap;
  }

  td {
    font-size: 13px;
    padding: 12px 16px;
    border-bottom: 1px solid $border-color;
    color: $text-color;
    white-space: pre-line;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tbody tr:hover {
    background-color: rgba($primary-color, 0.02);
  }
}

.popupActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  button {
    padding: 0 16px;
    height: 32px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    background-color: $primary-color;
    color: $white;
    border: none;

    &:hover {
      background-color: $secondary-color;
    }
  }
}

.loadingWrapper {
  padding: 48px;
  text-align: center;
  color: $text-color;
  
  .loader {
    border: 3px solid $border-color;
    border-top: 3px solid $primary-color;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto 16px;
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h3 {
    margin: 0;
    font-size: 18px;
    color: $text-color;
    font-weight: 500;
  }
}

.closeButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #637381;
  transition: all 0.2s;

  svg {
    font-size: 24px;
  }

  &:hover {
    color: $text-color;
  }
}

.urlLink {
  color: $primary-color;
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  font-size: 12px;
  
  &:hover {
    background-color: rgba($primary-color, 0.04);
  }
}

.budgetControls {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  .budgetInput {
    width: 100px;
    min-width: 100px;
    padding: 4px 8px;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }

  .budgetButtons {
    display: flex;
    gap: 4px;
    flex-shrink: 0;
  }

  .budgetBtn {
    padding: 4px 8px;
    min-width: 70px;
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    flex-shrink: 0;

    &:hover {
      background-color: $secondary-color;
    }
  }

  .percentButton {
    padding: 4px 8px;
    min-width: 60px;
    border: 1px solid $border-color;
    background-color: $white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    flex-shrink: 0;
    white-space: nowrap;

    &.increase {
      color: #2e7d32;
      &:hover {
        background-color: #e8f5e9;
      }
    }

    &.decrease {
      color: #d32f2f;
      &:hover {
        background-color: #ffebee;
      }
    }

    .icon {
      font-size: 10px;
    }
  }
}

.clickableRow {
  cursor: pointer;
  
  &:hover {
    background-color: rgba($primary-color, 0.02);
  }
}

.insightButton {
  padding: 4px 12px;
  background-color: $white;
  border: 1px solid $primary-color;
  border-radius: 4px;
  color: $primary-color;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 60px;

  &:hover {
    background-color: rgba($primary-color, 0.04);
  }
}

.insightDetail {
  padding: 16px;
  background-color: #f8f9fa;
  border-top: 1px solid $border-color;
  
  .insightTable {
    margin: 0;
    
    th, td {
      font-size: 12px;
      padding: 8px;
    }
  }
} 