.header {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0 2.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  z-index: 1000;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.98);
}

.logo {
  width: 160px;
  height: 36px;
  background: url('./assets/awake-logo.svg') no-repeat center center;
  background-size: contain;
  margin-right: 3rem;
  opacity: 0.9;
  
  &:hover {
    opacity: 1;
  }
}

.navbar {
  display: flex;
  gap: 2.5rem;
  margin-right: auto;
  height: 100%;
  align-items: center;
}

.navItem {
  color: #555;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  padding: 0.6rem 0;
  position: relative;
  transition: all 0.2s ease;
  letter-spacing: -0.2px;
  
  &:hover {
    color: #000;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.active {
  color: #000;
  font-weight: 600;
  position: relative;
  font-size: 13px;
  padding: 0.6rem 0;
  letter-spacing: -0.2px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: all 0.2s ease;
  }
}

.logoutButton {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #555;
  font-size: 13px;
  font-weight: 500;
  padding: 0.5rem 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  letter-spacing: -0.2px;
  
  &:hover {
    color: #000;
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgba(0, 0, 0, 0.12);
  }
}